$(function () {
    
    $(document).on('click touchstart', function (event) {

        // if any of the expand/close areas was clicked then switch the fold-out status and set cookie.
        if ($(event.target).hasClass('ContactArea-expandButton') || $(event.target).hasClass('ContactArea-expandButtonLarge')) {
            let checked = $('.ContactArea-toggle').is(":checked");
            cookieHelper.setCookie("contactAreaDefaultMode", !checked ? "open" : "closed", 365 * 100);
        }
    });

    checkWindowWidth();
});

function checkWindowWidth() {

    // if on a big screen check if the contact area shall be open on first load and if cookie is not set.
    if (window.innerWidth > 1600) {
        let contactAreaDefaultMode = cookieHelper.getCookie("contactAreaDefaultMode");
        if (contactAreaDefaultMode !== "closed") {
            $('#toggleContact').prop("checked", true);
        }
    }
}
